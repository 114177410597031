<template>
  <!-- 注册帐号页 -->
  <div class="singin">
    <div class="navbar" @click="navClick">
      <van-icon name="down" color="rgba(0, 0, 0, 0.85)" size="24" />
    </div>
    <div class="title">
      <p>{{ $t("setPass.title1") }}<br />{{ $t("setPass.title2") }}</p>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <span class="field_label">{{ $t("setPass.account") }}</span>
        <van-field
          v-model="username"
          name="username"
          :placeholder="$t('setPass.account_warn')"
          :rules="[{ required: true, message: $t('setPass.account_warn') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <span class="field_label">{{ $t("setPass.password") }}</span>
        <van-field
          v-model="password"
          name="password"
          type="password"
          maxlength="16"
          :placeholder="$t('setPass.password_warn')"
          :rules="[{ validator, message: $t('setPass.cfm_pass_warn2') }]"
        />
      </van-cell-group>
      <span class="field_bottom">{{ $t("setPass.password_warn2") }}</span>
      <van-cell-group>
        <span class="field_label">{{ $t("setPass.cfm_pass") }}</span>
        <van-field
          v-model="oldPass"
          name="oldPass"
          type="password"
          maxlength="16"
          :placeholder="$t('setPass.cfm_pass_warn')"
          :rules="[
            { validator: asyncValidator, message: $t('setPass.cfm_warn') },
          ]"
        />
      </van-cell-group>
      <div style="margin-top: 32px;">
        <van-button
          round
          block
          :loading="loading"
          type="primary"
          native-type="submit"
          >{{ $t("setPass.singup") }}</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import { encrypt } from "@/network/rsaEncrypt";
import { appRegister, appLogin } from "@/network/login";
export default {
  name: "setPassword",
  data() {
    return {
      loading: false,
      password: null,
      username: null,
      oldPass: null,
      bankNo: null,
      formData: {},
    };
  },
  created() {
    this.formData = this.$route.query.data;
  },
  methods: {
    navClick(){
      this.$router.push({
        path:'/singin',
        query:{
          bankName:this.$route.query.bankName,
          id:this.$route.query.id
        }
      })
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        if (!this.password) {
          Toast({
            message: this.$t("setPass.cfm_pass_warn"),
            duration: 1000,
          });
          return;
        }
        setTimeout(() => {
          resolve(val === this.password && true);
        }, 1000);
      });
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{8,16}$/.test(
        val
      );
    },
    onSubmit(values) {
      this.loading = true;
      let objData = { ...this.formData };
      objData.password = encrypt(values.password);
      objData.username = values.username;
      appRegister(objData)
        .then((res) => {
          if (!res) {
            this.$toast.success(this.$t("success"));
            window.sessionStorage.removeItem("singInfo");
            appLogin({
              username:values.username,
              password:objData.password
            })
              .then((res) => {
                if (res.token) {
                  window.localStorage.setItem("token", res.token);
                  window.localStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.user)
                  );
                  this.$router.replace("/home");
                } else {
                  this.$dialog
                    .alert({
                      message: res.message,
                      confirmButtonText: this.$t("stringCode.Done"),
                    })
                    .then(() => {
                      // on close
                    });
                }
              })
              .catch((err) => {
                this.$dialog
                  .alert({
                    message: err.message,
                    confirmButtonText: this.$t("stringCode.Done"),
                  })
                  .then(() => {
                    // on close
                  });
              })
              .finally(() => {
                this.loading = true;
              });
          } else {
            this.$dialog
              .alert({
                message: res.message,
                confirmButtonText: this.$t("stringCode.Done"),
              })
              .then(() => {
                // on close
              });
          }
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: err.message,
              confirmButtonText: this.$t("stringCode.Done"),
            })
            .then(() => {
              // on close
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.navbar {
  padding-top: 25px;
}
.singin /deep/ .van-icon {
  transform: rotate(90deg);
}
.singin {
  padding: 0 25px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
.title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.singin /deep/ .van-field__control::placeholder {
  color: #ccc;
}
.singin /deep/ .van-cell-group {
  background-color: #fafafa;
  margin-top: 16px;
}
.singin /deep/ .van-hairline--top-bottom::after {
  border-width: 0 0 1px;
}
.singin /deep/ .van-cell {
  padding: 8px 0;
  background-color: #fafafa;
}
.field_label {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.55);
}
.field_bottom {
  font-style: normal;
  font-weight: normal;
  padding-top: 8px;
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  color: #cccccc;
}
</style>
